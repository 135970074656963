@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300&display=swap");

body {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

.terms_main {
  padding-top: calc(100vw * 1 / 27);
  padding-bottom: calc(100vw * 1 / 27);
  text-align: center;
}

.terms_main h1 {
  font-size: 4rem;
  font-weight: 600;
  color: #000000;
}

.terms_main .terms_content {
  padding: 2rem 2rem;
}

.terms_main .terms_content p {
  font-size: 1.3rem;
  font-weight: 600;
  color: #4e4545;
}


.toast_message{
  position: fixed;
  left: 15px;
  bottom: 15px;
}