.boilerMain {
  background-color: #e3e4e5;
  padding-top: calc(100vw * 1.5 / 27);
  padding-bottom: calc(100vw * 1.5 / 27);
}

.boilerMain .boiler-sec h3 {
  font-size: 4.5rem;
  color: #000000;
}

.boilerMain .boiler-sec p {
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

/* .boilerMain .boiler-sec a {
  background-color: #009640;
  padding: 1rem 2rem;
  color: #ffffff;
  font-size: 1.3vw;
  text-decoration: none;
  border-radius: 50px;
  margin-top: 1rem;
} */

.boilerMain .boiler-sec a:hover {
  background: #000000;
}

.boiler-product-slider .prod-slider-items img {
  width: 100%;
}

.boilerMain .slick-slider .slick-arrow {
  display: none !important;
}

.boilerMain .boiler-sec .slider-prev-btn {
  border: 0;
  background: transparent;
  width: 45px;
  height: 45px;
  margin: 0.5rem;
}

.boilerMain .boiler-sec .slider-next-btn {
  border: 0;
  background: transparent;
  width: 45px;
  height: 45px;
  margin: 1rem 0rem;
}

.boilerMain .boiler-product-slider .prod-slider-items {
  border-left: 1px solid #009640;
  padding: 1rem 3rem;
}

.boilerMain .boiler-product-slider .prod-slider-items h5 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.boilerMain .boiler-product-slider .prod-slider-items span {
  display: block;
  font-weight: 500;
}

.boilerMain .boiler-product-slider .prod-slider-items span.prod-price {
  color: #009640;
  margin-top: 0.5rem;
}

.boilerMain .boiler-sec .articles .slick-slider .slick-list .slick-slide{
  padding: 0 15px;
}

.boilerMain .boiler-sec .articles .articles-items{
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0 10px #cccccc;
}

.boilerMain .boiler-sec .articles .articles-items img {
  width: 100%;
  height: 210px;
  object-fit: cover;
  border-radius: 5px;
}

.boilerMain .boiler-sec .articles .articles-items .article-info {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0 15px;
}

.boilerMain .boiler-sec .articles .articles-items .article-info .article-date {
  font-size: 1.1rem;
  color: #717272;
  font-weight: 500;
}

.boilerMain .boiler-sec .articles .articles-items .article-info .article-tags {
  color: #ffffff;
  background: #9c9ea0;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 0.7rem;
  font-weight: 600;
}

.boilerMain .boiler-sec .articles .articles-items h4.article-title {
  font-size: 1.7rem;
  color: #000000;
  font-weight: 600;
  line-height: 40px;
  padding: 0 15px;
  padding-bottom: 2rem;
  overflow-wrap: break-word;
}
