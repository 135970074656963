.boiler-quote-main {
  height: calc(100vh - 18vh);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.quote-options input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.quote-options input:checked + .quote-cc {
  background: #009640;
}

.quote-cc {
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}

.quote-options {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.quote-options .radio-item {
  margin: calc(100vw * 0.25 / 27);
  text-align: center;
  padding: calc(100vw * 0.5 / 27);
  max-width: 325px;
}

.cls-1,
.cls-2 {
  fill: none;
}
.cls-2 {
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 20px;
}

.quote-options .quote-options-label {
  background: #ededed;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0 10px #ededed;
  padding: 1rem;
  cursor: pointer;
}

.quote-options .quote-options-label:hover {
  background: #009640;
  transform: scale(1.1);
}

.quote-options .quote-options-label span {
  font-weight: 500;
  font-size: 1.2rem;
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
}

.quote-options .quote-options-label svg {
  width: 100%;
}

.quote-options .quote-options-label img {
  width: 100%;
}

.quote-options .quote-options-label svg .cls-1 {
  stroke: none;
}

.boiler-quote-main h3 {
  font-size: 2.9rem;
  font-weight: 500;
}

.boiler-quote-main p {
  font-size: 1.2rem;
  margin: 2rem 0;
}

.boiler-quote-main .submit_btn {
  background-color: #009640;
  color: #ffffff !important;
  border-radius: 50px;
  padding: 0.8rem 2rem !important;
  border: 0;
  font-size: 1.2rem;
}

.boiler-quote-main .submit_btn:hover {
  background: #000000;
}

.boiler-quote-main .submit_btn img {
  max-width: 25px;
  filter: invert(1);
  margin-top: -2px;
}

.boiler-quote-main .back_btn {
  background-color: #000000;
  color: #ffffff !important;
  border-radius: 50px;
  padding: 0.8rem 2rem !important;
  border: 0;
  font-size: 1.2rem;
  margin-right: 2rem;
}

.boiler-quote-main .refresh_btn {
  background: transparent;
  border: 0;
  font-weight: 600;
  font-size: 1.1rem;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #747474;
}
.boiler-quote-main .refresh_btn_dark svg {
  width: 30px;
  fill: #ffffff;
  height: 30px;
}

.boiler-quote-main .refresh_btn_dark {
  background-color: #bf2c25;
  color: #ffffff !important;
  border-radius: 50px;
  padding: 0.8rem 2rem !important;
  border: 0;
  font-size: 1.2rem;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 15px;
}

.boiler-quote-main .refresh_btn svg {
  fill: #747474;
  width: 30px;
  height: 30px;
}

.boiler-quote-main .contact-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.boiler-quote-main .contact-details .phone-info,
.boiler-quote-main .contact-details .chat-support {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: start;
}

.boiler-quote-main .contact-details .phone-info div span {
  display: block;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 500;
}

.boiler-quote-main .contact-details .chat-support div span {
  display: block;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 500;
}

.boiler-quote-main .contact-details .phone-info img,
.boiler-quote-main .contact-details .chat-support img {
  height: 70px;
}

.postcode_input {
  background: #e4edff;
  border: 0;
  display: block;
  box-shadow: 0 0 10px #e4e4e4;
  padding: 10px 20px;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
}

.boiler_quote_prod {
  padding: 3rem 0;
  background: rgb(0 150 65 / 15%);
}

.boiler_quote_prod .boiler_prod_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid;
  margin: 2rem 0;
}

.boiler_quote_prod .boiler_prod_header h3 {
  font-size: 3rem;
  font-weight: 600;
}

.boiler_quote_prod .boiler_prod_header p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 500;
}

.boiler_quote_prod .boiler_prod_item {
  background: #009640;
  padding: 3rem;
  color: #ffffff;
  margin: 3rem 0;
}

.boiler_quote_prod .light_boiler_ver {
  background: #ffffff;
  color: #000000;
}

.boiler_quote_prod .boiler_prod_item .prod_left h3 {
  font-size: 3rem;
  font-weight: 500;
}

.boiler_quote_prod .boiler_prod_item .prod_left p {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 3rem;
}

.boiler_quote_prod .boiler_prod_item .prod_left span {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 0;
}

.boiler_quote_prod .boiler_prod_item .prod_left span svg {
  max-width: 20px;
}

.boiler_quote_prod .boiler_prod_item .prod_left span span.percent_badge {
  border: 1.8px solid;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  text-align: center;
  padding: 4px;
  font-size: 12px;
}

.boiler_quote_prod .boiler_prod_item .prod_right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.boiler_quote_prod .boiler_prod_item .prod_right .prod_img img {
  width: 100%;
  max-width: 400px;
  min-width: 400px;
  box-sizing: border-box;
}

.boiler_quote_prod .boiler_prod_item .prod_right .prod_prices {
  width: 100%;
}

.boiler_quote_prod .boiler_prod_item .prod_right .prod_prices h4 {
  font-size: 2.5rem;
  text-decoration-style: dashed;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

.boiler_quote_prod .boiler_prod_item .prod_right .prod_prices .prod_price_box {
  border: 1px solid #000000;
  display: block;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .dark_line {
  background-color: #000000;
  color: #ffffff;
  padding: 10px;
  display: block;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount
  span {
  display: block;
  position: relative;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount
  span
  .price_cut {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgb(255 70 70);
  font-size: 0.9rem;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount
  span
  .price_cut
  span {
  text-decoration: line-through;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount {
  padding: 1rem 2rem;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount
  span
  .prod_real_price {
  font-size: 1.8rem;
  font-weight: 600;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount
  span
  .prod_note {
  font-size: 10px;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount
  span
  .prod_month_price {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: right;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount
  span
  .prod_month_note {
  font-size: 12px;
  text-align: right;
  text-decoration-line: underline;
  text-decoration-style: dotted;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount
  span.divider::after {
  content: "";
  width: 35px;
  position: absolute;
  overflow: hidden;
  border-top: 1px solid #000;
  transform: rotate(-63deg);
  top: -19px;
  left: 6px;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_price_box
  .prod_price_discount
  span.divider::before {
  content: "";
  width: 35px;
  position: absolute;
  overflow: hidden;
  border-top: 1px solid #000;
  transform: rotate(-63deg);
  bottom: -19px;
  left: -25px;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_submit
  button {
  background-color: #000000;
  color: #ffffff;
  margin-top: 2rem;
  border: 0;
  border-radius: 50px;
  font-weight: 600;
  padding: 0.8rem 3rem;
}

.boiler_quote_prod
  .boiler_prod_item
  .prod_right
  .prod_prices
  .prod_submit
  button:hover {
  background-color: #ffffff;
  color: #000000;
  margin-top: 2rem;
  border: 0;
  border-radius: 50px;
  padding: 0.8rem 3rem;
}

.answer_section h3 {
  font-size: 2.5rem;
}

.answer_section p {
  font-weight: 500;
  font-size: 1.2rem;
}

.answer_section .refresh_btn_dark {
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: underline;
}

.answer_section .refresh_btn_dark svg {
  width: 25px;
  display: block;
}

.answer_section .answer_list li {
  font-size: 1.2rem;
  list-style: none;
  border-bottom: 1px solid;
  padding: 0.6rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answer_section .answer_list li span {
  font-weight: 500;
}

.request-callback .form-input label {
  display: block;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.request-callback .form-input input {
  /* margin-bottom: 1rem; */
  border: 0;
  background: #e4e4e4;
  padding: 5px 10px;
  width: 100%;
  border-radius: 5px;
}

.request-callback .form-input textarea {
  border: 0;
  background: #e4e4e4;
  padding: 5px 10px;
  width: 100%;
  border-radius: 5px;
}

.request-callback .form-input span.form-error {
  color: #bf2c25;
  font-size: 0.7rem;
}

.request-callback .form-note {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  color: #949494;
}

.request-callback .form-btn button {
  background-color: #009640;
  color: #ffffff;
  border: 0;
  border-radius: 50px;
  font-weight: 600;
  padding: 0.5rem 2.5rem;
  margin-bottom: 1rem;
}

.request-callback .form-btn button:hover {
  background-color: #000000;
  color: #ffffff;
  border: 0;
  border-radius: 50px;
  font-weight: 600;
  padding: 0.5rem 2.5rem;
  margin-bottom: 1rem;
}

.quote-start-step .left_section {
  text-align: left;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 2px rgb(190, 190, 190);
}

.quote-start-step .right_section {
  text-align: left;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 2px rgb(190, 190, 190);
}

.quote-start-step .left_section ul,
.quote-start-step .right_section ul {
  padding-left: 0;
  list-style-type: none;
  padding: 1rem 0;
}

.quote-start-step .left_section ul li,
.quote-start-step .right_section ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 0.4rem;
}

.quote-start-step .left_section ul li:before,
.quote-start-step .right_section ul li:before {
  content: url("./assets/checkmark-circle-outline.svg");
  font-weight: 300;
  margin-left: -10px;
  margin-right: 10px;
  color: #27754e;
  position: absolute;
  left: 10px;
  top: 2px;
  width: 20px;
}

.quote-start-step .right_section .popular_text {
  color: #e09339 !important;
  font-weight: 500;
}

.quote-start-step .right_section .submit_btn {
  background: #e09339;
}

.quote-start-step .right_section .submit_btn:hover {
  background: #000000;
}
form.request-callback_another {
  background: #ebebeb;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
}
.request-callback_another .form-input {
  text-align: left;
  margin-bottom: 15px;
}
.request-callback_another .form-input label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}
.request-callback_another .form-input .form-error {
  display: block;
  color: #e20000;
  font-size: 13px;
  margin-top: 5px;
}

.request-callback_another .form-input input {
  border: 0;
  background: #ffffff;
  padding: 6px 13px;
  width: 100%;
}

.request-callback_another .form-input textarea {
  border: 0;
  background: #ffffff;
  width: 100%;
  padding: 6px 13px;
}

.request-callback_another .form-btn button {
  background-color: #009640;
  color: #ffffff;
  border: 0;
  border-radius: 50px;
  font-weight: 600;
  padding: 0.5rem 2.5rem;
  margin-bottom: 1rem;
}

.request-callback_another .form-btn button:hover {
  background: #000000;
  text-shadow: 0 0 10px #787878;
  box-shadow: 0 0 10px #ccc;
}

.boiler_quote_prod_another .form_head h3 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.boiler_quote_prod_another .form_head p {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 500;
  color: #7a7878;
}

.export-quote-main {
  max-width: calc(100vh - 25vh);
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 767px) {
  .export-quote-main {
    padding: 20px;
    max-width: 100% !important;
  }  
}
