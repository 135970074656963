.banner-sec {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(#0000003a, #00000031),
    url(../../Assets/banner.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
}

.banner-sec .banner-overlay {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(transparent, rgb(0 0 0 / 48%));
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
}

.banner-sec .banner-text h3 {
  font-weight: 700;
  font-size: 3rem;
  color: #ffffff;
}
.banner-sec .banner-text p {
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
}

.banner-sec .banner-text a {
  background-color: #009640;
  color: #ffffff !important;
  border-radius: 50px;
  padding: 0.5rem 2rem !important;
  text-decoration: none;
  margin-top: 1rem;
  display: inline-block;
}
