.contact_cta_main {
  background: #009640;
  padding-top: calc(100vw * 1.5 / 27);
  padding-bottom: calc(100vw * 1.5 / 27);
  text-align: center;
}

.contact_cta_main h3 {
  font-size: 4rem;
  color: #ffffff;
}

.contact_cta_main p {
  color: #ffffff;
  font-size: 1.5rem;
}

.contact_cta_main a {
  display: block;
  background: #000000;
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  border-radius: 50px;
  padding: 1rem 2rem;
  box-sizing: border-box;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 1rem;
}
