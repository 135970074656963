.cta .cta-sec {
  padding-top: calc(100vw * 1.8 / 33);
  padding-bottom: calc(100vw * 1.8 / 33);
}

.cta .cta-sec .cta-img {
  text-align: center;
}

.cta .cta-sec .cta-img span{
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #000000;
}

.cta .cta-sec .cta-img a{
  font-weight: 600;
  font-size: 4rem;
  color: #009640;
  text-decoration: none;
}

.cta .cta-sec .cta-img svg {
  max-width: 250px;
}

.cta .cta-sec .cta-content h4 {
  font-size: 3rem;
  line-height: 60px;
}

.cta .cta-sec .cta-content p {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.cta .cta-sec .cta-content a {
  background-color: #009640;
  padding: 1rem 3rem;
  color: #ffffff;
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 50px;
  margin-top: 1rem;
}
